body {
  margin: 0;
  padding: 0;
  font-family:Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  font-size: 14px;
}

@primary-color: #5F3996;@error-color: #EF4747;@outline-width: 0px;@popover-distance: 0px;@layout-body-background: #F2F4F7;@progress-default-color: @primary-color;@font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;