@import '~antd/dist/antd.less';

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cad6e3;
  border-radius: 10px;
}

.ant-select-dropdown,
.ant-select-item,
.ant-popover {
  font-size: 1em;
  padding: 0;
}

.ant-select-item-option-content {
  margin: 8px 16px;
  display: flex;
  align-items: center;
}
.ant-select-item-option-content .ant-tag {
  margin-right: 0;
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > div {
    min-width: 10px;
  }
}
.popover-no-padding .ant-popover-inner-content {
  padding: 0;
}
.popover-no-arrow .ant-popover-arrow {
  display: none;
}
.main-sidebar-block-drawer .ant-drawer-body {
  padding: 0;
  overflow: hidden;
  font-size: 1em;
}
.ant-modal-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ant-modal {
  font-size: 1em;
  top: 0;
  .ant-modal-body {
    font-size: 1em;
  }
}
.sortableHelper {
  z-index: 1000;
}
li.sortableHelper {
  list-style-type: none;
}
.btn-no-shadow {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.callback-wrapper {
  width: 100%;
}
.callback-wrapper .loader-wrapper {
  height: 100vh;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.xxl-space {
  padding: 25px;
}
.xl-space {
  padding: 20px;
}
.lg-space {
  padding: 15px;
}
.md-space {
  padding: 10px;
}
.sm-space {
  padding: 5px;
}
.xs-space {
  padding: 2px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-2 {
  margin-bottom: 0.5em;
}

.mw-20 {
  width: 20px !important;
  min-width: 20px;
}
.check-mark {
  width: 29px !important;
  min-width: 29px;
}
.d-none {
  display: none;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.align-items-center {
  align-items: center;
}
.justify-space-between {
  justify-content: space-between;
}
.text-capitalize {
  text-transform: capitalize;
}
.no-box-shadow {
  box-shadow: none;
}
.ant-tabs-bar {
  border-bottom-color: #d9d9d9;
}
.ant-empty-normal {
  margin: 8px 0;
}
.no-padding {
  padding: 0 !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.padding-col {
  padding: 8px !important;
}
.padding-right {
  padding-right: 27px !important;
}
.react-joyride__beacon:focus {
  outline: none;
}
.ant-table-cell *:focus {
  outline: none;
}
.ant-popover-inner {
  border-radius: 8px;
}
.ant-select-dropdown {
  border-radius: 8px;
}
.ant-select-item-option-content {
  color: #515983;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f2f4f7;
}
.ant-spin-container::after {
  background: inherit;
}
.ant-switch {
  background-color: #cad6e3;
}
.ant-switch-checked {
  background-color: #515983;
}

.ant-select-selector {
  background-color: transparent !important;
}
.ant-message {
  &-notice-content {
    padding: unset;
    border-radius: 12px;
    color: #515983;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  &-error {
    padding: 10px;
    border-radius: 12px;
    background: #fbedea;
    border: 1px solid #f5c7bd;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(245, 199, 189, 0.5);
  }
  &-success {
    padding: 10px;
    background: #eaf7ee;
    border: 1px solid #bee5c8;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(190, 229, 200, 0.5);
    border-radius: 12px;
  }
  &-info {
    padding: 10px;
    background: #f0e8fd;
    border: 1px solid #dacbf0;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(218, 203, 240, 0.5);
    border-radius: 12px;
  }
  &-warning {
    padding: 10px;
    background: #fef7e9;
    border: 1px solid #f9deaf;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px rgba(249, 222, 175, 0.5);
    border-radius: 12px;
  }
}
.ant-picker-panel-container {
  border-radius: 14px;
}
.ant-btn-sm {
  border-radius: 12px;
  height: auto;
}
.ant-select-item-option-state {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 30px;
  cursor: pointer;
  opacity: 0;
}
.no-wrap {
  flex-wrap: nowrap;
}
.ant-form-item-label > label {
  font-size: 12px;
  color: #515983;
  height: 16px;
  flex-direction: row-reverse;
}
.ant-form-item-label > label.ant-form-item-required::before {
  margin-left: 3px;
}
.ant-form-item-label {
  padding: 0 0 0 4px;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden !important;
}
.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}
.ant-tag:hover {
  opacity: 1;
}
.weight-500 {
  font-weight: 500 !important;
}
.hide-scrollbar {
  scrollbar-width: none;
}
.cursor-pointer {
  cursor: pointer;
}
.ant-form-item-label > label.ant-form-item-required::before {
  font-size: 12px;
  line-height: 16;
}
[ant-click-animating-without-extra-node='true']::after {
  display: none;
}

@primary-color: #5F3996;@error-color: #EF4747;@outline-width: 0px;@popover-distance: 0px;@layout-body-background: #F2F4F7;@progress-default-color: @primary-color;@font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;